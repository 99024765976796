body {
  background-color: #f8f9fa;
  font-family: "Kanit", sans-serif;
}
.header {
  padding: 10px 0;
  color: white;
  border-bottom: 1px solid #ffffff4f;
  margin-bottom: 20px;
}
.header .logo {
  font-size: 24px;
  font-weight: bold;
  color: #8cc63f;
}
.header .nav-link {
  color: white;
  margin-right: 20px;
}
.dialer_bg {
  background-color: #4b4b4b;
  background: radial-gradient(
      35.28% 303.21% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      #535355 100%
    ),
    radial-gradient(
      23.08% 128.71% at 50% 50%,
      #7e7e80 0%,
      #777779 0%,
      #59595b 100%
    ),
    linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #59595b 100%),
    radial-gradient(32.9% 241.16% at 50% 50%, #d9d9d9 0%, #737373 100%);
  min-height: 600px;
  position: relative;
}
.contact_bg {
  background-color: #4b4b4b;
  background: radial-gradient(
      35.28% 303.21% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      #535355 100%
    ),
    radial-gradient(
      23.08% 128.71% at 50% 50%,
      #7e7e80 0%,
      #777779 0%,
      #59595b 100%
    ),
    linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #59595b 100%),
    radial-gradient(32.9% 241.16% at 50% 50%, #d9d9d9 0%, #737373 100%);
  height: 100%;
  min-height: 100vh;
}
.content_bg {
  background-color: #4b4b4b;
  background: radial-gradient(
      35.28% 303.21% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      #535355 100%
    ),
    radial-gradient(
      23.08% 128.71% at 50% 50%,
      #7e7e80 0%,
      #777779 0%,
      #59595b 100%
    ),
    linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #59595b 100%),
    radial-gradient(32.9% 241.16% at 50% 50%, #d9d9d9 0%, #737373 100%);
  height: 100%;
  min-height: 100vh;
}
.recent-contacts {
  color: white;
}
.contact-card {
  padding: 10px;
  display: flex;
  align-items: center;
  color: white;
  gap: 15px;
}
.contact-card .call-icon {
  background: #00000036;
  padding: 5px;
  border-radius: 50%;
}
.contact-card .call-icon span {
  background: #8cc63f;
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
}
.recent-contacts .owl-item {
  margin-right: 0px;
}
.pro_info p {
  font-size: 11px;
  color: #e4fec8;
  font-weight: 300;
  margin-bottom: 0;
}
.pro_info h6 {
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
}
.recent-contacts .carousel-control-next,
.recent-contacts .carousel-control-prev {
  width: auto;
}
.recent-contacts .carousel-control-prev {
  left: -30px;
}
.recent-contacts .carousel-control-next {
  right: -30px;
}
.dialer {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  margin: 0 auto;
  width: 80%;
  top: 120px;
}
.dialer .profile-pic {
  margin-bottom: 10px;
}
.dialer .number-pad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
}
.dialer .number-pad button {
  background-color: #e9ecef;
  border: none;
  border-radius: 50%;
  padding: 20px;
  font-size: 24px;
  width: 100%;
  height: 90px;
  max-width: 90px;
  margin: auto;
  line-height: 24px;
  font-weight: 500;
}
.dialer .call-button {
  background-color: #6bc40a;
  border: none;
  border-radius: 50%;
  padding: 20px;
  color: white;
  display: flex;
  align-items: center;
}
.dialer-container {
  position: absolute;
  top: 10px;
  left: 50px;
}
.on-call {
  background-color: (#ffffff, 50%);
  display: block;
  z-index: 999;
  width: 100%;
  height: 100vh;
  top: 0;
  position: absolute;
}
.on-call .dialer {
  position: relative;
}
.call-button span {
  font-size: 40px;
  color: #000;
}
.logo .logo_img {
  width: 100%;
  max-width: 200px;
}
.logo {
  text-align: center;
}
.headphone_icon span {
  background: #ffffff;
  color: #6bc40a;
  padding: 10px;
  font-size: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.nav_section .nav .nav-link {
  font-size: 18px;
  padding: 0;
  font-weight: 400;
}
.recent-contacts h5 {
  font-size: 24px;
  margin-bottom: 0;
}
.nav_section .nav .nav-link.active,
.text_light_green {
  color: #b2ff5d;
}
.nav_section .nav .nav-item:nth-of-type(1),
.nav_section .nav .nav-item:nth-of-type(2) {
  border-right: 2px solid #fff;
  margin-right: 15px;
}
.profile_section img {
  max-width: 40px;
  width: 100%;
  margin-right: 10px;
}
.material-symbols-outlined.fill {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
.dialer .number-pad button span {
  font-size: 14px;
  display: block;
  font-weight: 400;
}
.dialer h6 {
  font-size: 24px;
  color: #1861bf;
  margin-bottom: 0;
}
.dialer p {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}
.call-button.back_space_btn {
  background: #e9ecef !important;
}
.call-icon {
  cursor: pointer;
}
.contact_search .form-control {
  width: 250px;
  background: #6e6e6f;
  border: none;
  border-radius: 50px !important;
  color: #fff;
  font-weight: 300;
  height: 40px;
  border: 1px solid #edf2fa5c;
}
.btn-outline-secondary {
  position: absolute !important;
  border: none;
  right: -6px;
}
.contact_search .btn-outline-secondary span {
  display: flex;
  padding: 4px;
  align-items: center;
  background: #8cc63f;
  font-size: 18px;
  color: #fff;
  border-radius: 50px;
}
.contact_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.hamburger_menu {
  border: none;
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  margin-left: 10px;
}
#navbarSupportedContent {
  background: #484848;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 30px;
  height: 2px;
  background-color: #333;
  position: relative;
}
.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 2px;
  background-color: #333;
  position: absolute;
}
.navbar-toggler-icon::before {
  top: -10px;
}
.navbar-toggler-icon::after {
  top: 10px;
}
.profile_name {
  background: #a3d869;
  padding: 10px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}
.recent-contacts .owl-nav .owl-prev {
  position: absolute;
  left: -10px;
  top: 14px;
  margin: auto;
}
.recent-contacts .owl-nav .owl-next {
  position: absolute;
  right: -10px;
  top: 14px;
  margin: auto;
}
.recent-contacts .owl-nav > button {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.recent-contacts .owl-nav > button img {
  width: 26px;
  opacity: 0.6;
}
.recent-contacts .owl-nav > button img:hover {
  width: 24px;
  opacity: 1;
}
.contact-card img {
  max-width: 50px;
  width: 100%;
  border-radius: 50%;
}
.call-button:hover {
  transform: translateY(-3px);
  transition: all 0.1s;
}
.call_min span {
  background: #a3d869;
  padding: 5px 15px;
  border-radius: 50px;
  color: #000;
  font-weight: 500;
}
.dialer .end_call,
.contact-card .oncall span {
  background: red;
  color: #fff;
}
.dialer .end_call span {
  color: #fff;
}
.oncall_btn_section .call-button {
  text-align: center;
  margin: 0 auto;
}
.oncall_btn_section {
  background: #f8f9fa;
  border-radius: 20px;
  padding: 30px;
  margin-top: 100px !important;
}

.subscription-form {
  background-color: #8b8b8d;
  border-radius: 10px;
  padding: 10% 10px;
  margin: 5px 15px;
  display: block;
  align-items: center;
  color: white;
  gap: 15px;
  border: 1px solid #ffffff7d;
  justify-content: space-between;
  height: 100%;
}

.submit-btn {
  background: #a3d869 !important;
  color: #333 !important;
  border-color: #a3d869 !important;
}

.dialPad {
  width: 100% !important;
  position: fixed;
  top: 180px;
}

.dialer_bg.dialer_bg_new {
  height: 100%;
  min-height: 100%;
}

.dialer_oncall .on-call {
  position: inherit !important;
  width: 100% !important;
}

.dialer_oncall .on-call .dialer {
  position: inherit !important;
  width: 100% !important;
}
.slider {
  max-height: 85vh;
  overflow-y: scroll;
  scrollbar-color: #b2ff5d white;
  scrollbar-width: thin;
}

.slider .news-list {
  margin-right: 15px;
}

.btn-viewrecent {
  background-color: #8cc63f !important;
  border-radius: 0 !important;
}

a {
  color: #a3d869 !important;
}
a:hover {
  color: #719d40 !important;
}
.d-none.show {
  display: block !important;
}

.welcomeText {
  background-color: #8b8b8d;
  border: 1px solid #ffffff7d;
  padding: 25px;
  margin-top: 4px;
  border-radius: 10px;
  height: 100%;
}

iframe {
  width: 100% !important;
  height: 100vh !important;
}

.spinner {
  width: 72px;
  height: 72px;
  display: grid;
  border: 5.8px solid #0000;
  border-radius: 50%;
  border-color: #e1f0dd #0000;
  animation: spinner-e04l1k 1s infinite linear;
  margin: 0 auto;
  top: 30%;
  position: relative;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.9px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: #8cc63f #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 11.6px;
}

.rs-nav-tabs.rs-nav-horizontal > .rs-nav-item,
.rs-nav-tabs.rs-nav-horizontal > .rs-dropdown .rs-dropdown-toggle {
  border-radius: 0px !important;
  background: none;
  border: none;
  color: #ffffff;
}
.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  color: #8cc63f;
  background: none;
  border: none;
}

.sideBarTitle {
  border-bottom: 1px solid #ffffff;
  padding: 5px;
  color: #ffffff;
  font-size: 22px;
  margin-bottom: 0;
}

@keyframes spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}

@media only screen and (max-width: 1199px) {
  .dialer {
    top: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .dialer_bg {
    min-height: 700px;
  }
  .dialer {
    top: 120px;
  }
  .recent-contacts .carousel-control-prev {
    left: 0px;
  }

  .recent-contacts .carousel-control-next {
    right: 0px;
  }
  .profile_section_hidden,
  .mobile_nav_hidden {
    display: none !important;
  }
  .nav_section .nav .nav-item:nth-of-type(1),
  .nav_section .nav .nav-item:nth-of-type(2) {
    border-right: none;
    margin-right: 0;
  }
  .nav_section .nav-item {
    margin-bottom: 10px;
  }

  iframe .rc-old.full-page {
    padding-top: 62px;
  }
}

@media only screen and (max-width: 600px) {
  .contact_heading {
    text-align: center;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .recent-contacts {
    padding: 0 20px;
  }
  .dialer {
    top: 120px;
  }
  .contact-card {
    width: 300px;
    margin: 10px auto;
  }
  .recent-contacts h5 {
    text-align: center;
    margin-bottom: 10px;
  }
  .profile_section .text_light_green {
    color: #b2ff5d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 74px;
    display: inline-block;
  }
  .sideBar {
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .dialer {
    min-width: 80%;
  }
  .dialer .number-pad button {
    padding: 10px;
    height: 70px;
    max-width: 70px;
  }
}

@media only screen and (max-width: 400px) {
  .dialer {
    min-width: 80%;
  }
  .contact-card {
    width: 100%;
    margin: 10px auto;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 650px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
